import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import { getBrText } from "../helpers/helper.rendering"

import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"

const Investors = () => {

  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])


  return (
    <div className="tasaciones">
      <div className="container-fluid">
        <div className="row justify-content-between d-none d-lg-block index-1">
          <div className="col-2">
            <div className="line-vertical"></div>
          </div>
          <div className="col-2">
            <div className="line-vertical"></div>
          </div>
          <div className="col-2">
            <div className="line-vertical"></div>
          </div>
          <div className="col-2">
            <div className="line-vertical"></div>
          </div>
          <div className="col-2">
            <div className="line-vertical"></div>
          </div>
        </div>
        <div className="row justify-content-lg-between">
          <div className="col-lg-12 pe-xxl-0">
            <span>Conocé el valor de tu propiedad</span>
            <div className="row d-flex justify-content-center align-items-stretch">
              <div className="col-xxl-8 col-xl-8">
                <h2>
                  Ahora podés tasar tu propiedad de forma rápida y segura.
                  Completá los datos así podemos brindarte un valor aproximado.
                  ¿Calculamos?
                </h2>
              </div>
              <div className="col-xxl-4 col-xl-4 justify-content-lg-end d-flex">
                <div className="img-cut">
                  <img src="/images/home-tasaciones.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end justify-content-lg-start">

              <Link to={ "/tasaciones"} className="btn-square dark-hover d-inline-block">
                SOLICITAR TASACIÓN
              </Link>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Investors
