import { Link } from "gatsby"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import VisibilitySensor from "react-visibility-sensor"
import CountUp from "react-countup"

const About = () => {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        sections {
          home {
            about {
              title
              paragraph
              image
              buttons {
                link
                value
              }
              items {
                paragraph
                value
              }
            }
          }
          about {
            image
          }
        }
      }
    }
  `)

  function getCurrentYear() {
    return new Date().getFullYear().toString().replace("20",'')
  }

  return (
    <div className="about">
      <div className="container-fluid">
        <img src="images/sello.svg" alt="" className="sello" />
        <div className="row">
          <div className="col-lg-3">
            <h2>NOSOTROS</h2>
            <span>INMOBILIARIA BOUTIQUE</span>
            <span className="d-flex align-items-center justify-content-start w-100">
              20 <div className="line d-block mx-1"> </div> {getCurrentYear()}
            </span>
          </div>
          <div className="col-lg-8 offset-lg-1">
            <p>
              La sociedad surge con el deseo de emprender en la ciudad{" "}
              <br className="d-lg-block d-none" />
              un nuevo concepto inmobiliario, en base a la experiencia{" "}
              <br className="d-lg-block d-none" />
              vivida de ambos fundadores en el mercado.{" "}
              <br className="d-lg-block d-none" />
              <br />
              <br />
              Priorizando la atención personalizada y con el uso de nuevas{" "}
              <br className="d-lg-block d-none" />
              tecnologías, buscamos que nuestros clientes tengan la mejor{" "}
              <br className="d-lg-block d-none" />
              experiencia al momento de invertir en un inmueble, simplificando{" "}
              <br className="d-lg-block d-none" />
              al máximo la toma de decisiones.
              <br className="d-lg-block d-none" />
              <br />
              <br />
              Es por eso que al momento de realizar una inversión{" "}
              <br className="d-lg-block d-none" />
              somos su mejor opción.
            </p>
            <Link
              to={"nosotros"}
              className="btn btn-icon transparent d-inline-block"
            >
              QUIERO CONOCER MÁS
            </Link>
          </div>
        </div>
        <div className="row justify-content-between index-1">
          <div className="col-2">
            <div className="line-vertical"></div>
          </div>
          <div className="col-2">
            <div className="line-vertical"></div>
          </div>
          <div className="col-2">
            <div className="line-vertical"></div>
          </div>
          <div className="col-2">
            <div className="line-vertical"></div>
          </div>
          <div className="col-2">
            <div className="line-vertical"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
