import { Link } from 'gatsby';
import React, {useEffect, useState, useRef}  from 'react';
import Searcher from '../search'
import { connect } from 'react-redux';
import { getDevelopmentsAction } from '../../redux/developmentsDucks';
import {getTotalListFilters} from '../../redux/propertiesDucks'
import Loading from '../Loading'
import OwlCarousel from 'react-owl-carousel3'

//Helpers
import { getLocation, getLastFeatured, getFakeAddress } from '../../helpers/helper.developments';
import { getCover } from '../../helpers/helper.rendering';
import { graphql, useStaticQuery } from 'gatsby';
import { getActionByPage, getActionByType, getCodeFromType, getCodes, getSectionFromCode } from '../../helpers/helper.actions';
import { informCustomEvent } from '../../helpers/helper.analytics';
import { useGetPushQuery } from "../../redux/mediacore/push"
import { useGetDevelopmentsQuery } from '../../redux/middlewareTokkoApi/developments';

const Home_Main = ({developments,api_key,dispatch,actions}) =>  {

    const slider = useRef()

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
            env { 
                CLIENTID
              }
              keys{
                tokko
              }
              slogan
              main_image
              proFeatures{
                locationsAdv
            }
          }
    }`)

    const {template_version,proFeatures} = realEstate
    const {locationsAdv} = proFeatures

    const {env} = realEstate;
    const { data: allPushData, isLoading: loading } = useGetPushQuery(env.CLIENTID);

    // const slider_action = getActionByPage(getActionByType(actions,'Slider de Imagenes Encabezado'),'Inicio')
    // const image_action = getActionByPage(getActionByType(actions,'Imagen Encabezado'),'Inicio')
    // const video_action = getActionByPage(getActionByType(actions,'Video Encabezado'),'Inicio')
    const slider_action = getActionByPage(getActionByType(allPushData,getCodeFromType('Slider de Imagenes Encabezado')),("INI"))
    const image_action = getActionByPage(getActionByType(allPushData,getCodeFromType('Imagen Encabezado')),("INI"))
    const video_action = getActionByPage(getActionByType(allPushData,getCodeFromType('Video Encabezado')),("INI"))

    const { data: allDevelopmentsData } = useGetDevelopmentsQuery(realEstate.keys.tokko);

    const makeLink = (development) => {
        if(development){
            return "/emprendimientos/" + getFakeAddress(development).replaceAll(" ","-") + "/" + development.id
        }
    }

    const getSize = () => (screen.width < 992) ? false : true

    const goTo = () => {
        if (video_action[0].url_destiny) {
            window.open(video_action[0].url_destiny + video_action[0].text_utm, '_blank', 'noopener,noreferrer')
            informCustomEvent("PUSH_" + getCodes(video_action[0].type,video_action[0].section) + "_" + (video_action[0].name))
        }
    }

    useEffect(() => {
        if(video_action.length > 0){
            informCustomEvent("SHOW_" + getCodes(video_action[0]?.type,video_action[0]?.section) + "_" + video_action[0]?.name) 
        }
        if(image_action.length > 0){
            informCustomEvent("SHOW_" + getCodes(image_action[0]?.type,image_action[0]?.section) + "_" + image_action[0]?.name) 
        }
        if(slider_action.length > 0){
            informCustomEvent("SHOW_" + getCodes(slider_action[0]?.type,slider_action[0]?.section) + "_" + slider_action[0]?.name) 
        }
    },[])

    const [stateSlider,setStateSlider] = useState("uno")

    return (
        <div className={"main " + stateSlider}>
          <div className="transparencia d-block g-lg-none"></div>
          <div className="container-fluid">
            <div className="row" id="content-main">
              <div className="col-lg-4 d-flex align-items-start justify-content-lg-center flex-column">
                <h1>
                  Encontrá <br />
                  tu  
                  {stateSlider != "dos" ? ' hogar' : ' oficina'}.
                </h1>
                <p>El estilo de vida que siempre soñaste.</p>
              </div>
            </div>
            <div className="navigator-container d-flex">
              <div className={"item-navigator cursor-pointer " + (stateSlider == "uno" ? 'active' : '')} onClick={() => setStateSlider("uno")}>
                01 <span className="line-navigator" ></span> HOGAR
              </div>
              <div className={"item-navigator cursor-pointer " + (stateSlider == "dos" ? 'active' : '')} onClick={() => setStateSlider("dos")}>
                02 <span className="line-navigator"></span> OFICINAS
              </div>
              <div className={"item-navigator cursor-pointer " + (stateSlider == "tres" ? 'active' : '')} onClick={() => setStateSlider("tres")}>
                03 <span className="line-navigator"></span> EMPRENDIMIENTOS
              </div>
            </div>
            <Searcher />
          </div>
          <div className="line-degrade"></div>
        </div>
      );
}

export default connect(state => ({
    developments: state.developments.developments,
    actions:state.actions.actions,
    loading: state.developments.loading,
    api_key: state.settings.keys.tokko
}),null)(Home_Main);